<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Rutas</h5>
              </div>

              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Basico</li>
                  <li class="breadcrumb-item active">Rutas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-10"></div>
                  <div class="col-md-2">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_tifrutas_export"
                        v-if="$store.getters.can('tif.rutas.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        @click="create()"
                        v-if="$store.getters.can('tif.rutas.create')"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <th style="width: 50%">
                      Nombre
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="filtros.nombre"
                        @keyup="getIndex()"
                      />
                    </th>
                    <th style="width: 30%">
                      Bloque
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="filtros.bloque"
                        @keyup="getIndex()"
                      />
                    </th>
                    <th>
                      Estado
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.estado"
                        @change="getIndex()"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="estado in listasForms.estados"
                          :key="estado.numeracion"
                          :value="estado.numeracion"
                        >
                          {{ estado.descripcion }}
                        </option>
                      </select>
                    </th>
                  </thead>
                  <tbody>
                    <tr v-for="item in rutas.data" :key="item.id">
                      <td>{{ item.nombre }}</td>
                      <td>{{ item.bloque.nombre }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-bind:class="[
                            item.estado == 1 ? 'badge-success' : 'badge-danger',
                          ]"
                        >
                          {{ item.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                        </span>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-left">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            style="cursor: pointer"
                            v-if="$store.getters.can('tif.rutas.edit')"
                            @click="edit(item)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="rutas.total">
                  <p>
                    Mostrando del <b>{{ rutas.from }}</b> al
                    <b>{{ rutas.to }}</b> de un total:
                    <b>{{ rutas.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="rutas"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <TifRutasExport ref="TifRutasExport" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import TifRutasExport from "./TifRutasExport";

export default {
  name: "rutaIndex",
  components: {
    Loading,
    pagination,
    TifRutasExport,
  },
  data() {
    return {
      cargando: false,
      rutas: {},
      filtros: {
        nombre: null,
        bloque: null,
        estado: null,
      },
      listasForms: {
        estados: {},
        tipoSitio: {},
      },
    };
  },
  methods: {
    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/tif/rutas?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.rutas = response.data;
          this.cargando = false;
        });
    },

    create() {
      return this.$router.push({
        name: "/Tif/RutasForm",
        params: { accion: "Crear", id: null },
      });
    },

    edit(ruta) {
      return this.$router.push({
        name: "/Tif/RutasForm",
        params: { accion: "Editar", data_edit: ruta, id: ruta.id },
      });
    },

    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
  },
  mounted() {
    this.getIndex();
    this.getMsg();
    this.getEstados();
  },
};
</script>
